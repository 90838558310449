import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const BannerWithContact = () => {
  // Animation variant
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  };

  const FadeUpText = (delay) => {
    return {
      initial: { opacity: 0, y: 30 },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: delay,
          ease: "easeInOut",
        },
      },
    };
  };

  return (
    <section className="bg-gray-100 py-14 md:py-24">
      <div className="container flex flex-col items-center space-y-12">
        {/* Join Our Community Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-4"
        >
          <motion.h2
            variants={FadeUpText(0.1)}
            initial="initial"
            animate="animate"
            className="text-4xl font-bold"
          >
            Join Our Community Today!
          </motion.h2>
          <motion.p
            variants={FadeUpText(0.2)}
            initial="initial"
            animate="animate"
            className="text-dark2"
          >
            Ready to turn your aspirations into achievements? Explore our courses, apply for scholarships, and
            take advantage of our expert training. With Sankhyana, your future in technology is within reach.
          </motion.p>



{/* Social Media Links Section */}
<motion.div
          className="flex justify-center gap-6 mt-6"
          initial="initial"
          whileInView="animate"
          variants={fadeIn}
        >
          <motion.a
            href="https://www.facebook.com/SankhyanaKenya/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
            whileHover={{ scale: 1.2 }}
          >
            <FontAwesomeIcon icon={faFacebookF} size="2x" />
          </motion.a>
          <motion.a
            href="https://www.instagram.com/sankhyanakenya?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-600"
            whileHover={{ scale: 1.2 }}
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </motion.a>
          <motion.a
            href="https://www.linkedin.com/showcase/78795866/admin/dashboard/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
            whileHover={{ scale: 1.2 }}
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </motion.a>
          <motion.a
            href="https://x.com/sankhayana?s=11"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400"
            whileHover={{ scale: 1.2 }}
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </motion.a>
          <motion.a
            href="https://www.whatsapp.com/channel/0029VaG9xJJ5K3zNNr0xgj0E"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-600"
            whileHover={{ scale: 1.2 }}
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </motion.a>
</motion.div>




        {/* WhatsApp Groups and Channel */}
        <div className="text-center space-y-2">
        {/* Join whatsapp Group */}
        <h1 className="text-4xl font-bold">Join whatsapp Group</h1>

        <motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.3, duration: 0.5 }}
>
  <a 
    href="https://chat.whatsapp.com/E5SYS0UWCClLkdwUpJsTdC"
    target="_blank"
    rel="noopener noreferrer"
    className="text-green-600 hover:underline"
  >
    Sankhyana India
  </a>
</motion.p>

<motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4, duration: 0.5 }}
>
  <a
    href="https://chat.whatsapp.com/EVUR9dsddHj9u4Db6ZQOY9"
    target="_blank"
    rel="noopener noreferrer"
    className="text-green-600 hover:underline"
  >
    Sankhyana India 2
  </a>
</motion.p>

<motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.5, duration: 0.5 }}
>
  <a
    href="https://chat.whatsapp.com/CCR2VqsKTzLK6efz2J3nMj"
    target="_blank"
    rel="noopener noreferrer"
    className="text-green-600 hover:underline"
  > 
    Sankhyana India 3
  </a>
</motion.p>

<motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.6, duration: 0.5 }}
>
  <a 
    href="https://chat.whatsapp.com/FWstDk2Og3X6GuqDTzE8Zm"
    target="_blank"
    rel="noopener noreferrer"
    className="text-green-600 hover:underline"
  >
    Sankhyana Africa
  </a>
</motion.p>

<motion.p
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.7, duration: 0.5 }}
>
  <a
    href="https://chat.whatsapp.com/FJgHlql1maW93ObEtqJdSp"
    target="_blank"
    rel="noopener noreferrer"
    className="text-green-600 hover:underline"
  >
    Sankhyana Africa 2
  </a>
</motion.p>






        </div>
        



        {/* <div className="flex flex-col md:flex-row mt-4 space-x-0 md:space-x-4"> */}
  
        <div className="row justify-content-center mt-4">
  <div className="col-md-10">
    <div className="row">
    <motion.div
            variants={FadeUpText(0.3)}
            initial="initial"
            animate="animate"
            className="mt-4 space-y-2 text-lg text-dark2"
          >
            <p>Contact Us Today</p>
            <p>
              <strong>Email: </strong>
              <a href="mailto:info@sankhyana.com" className="text-blue-600 hover:underline">
                info@sankhyana.com
              </a>
            </p>
            <p>
              <strong>WhatsApp: </strong>
              <a href="tel:+917406120321" className="text-blue-600 hover:underline">
                +91 +91 7406120321
                 </a>
            </p>
            <p>
              <strong>Website: </strong>
              <a href="https://www.sankhyana.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                www.sankhyana.com
              </a>
            </p>
          </motion.div>

      {/* India Contact Section */}
      {/* <div className="col-md-6">
        <motion.div
          variants={FadeUpText(0.3)}
          initial="initial"
          animate="animate"
          className="space-y-4 text-lg text-dark2"
        >
          <h2 className="text-xl font-semibold">Contact Us - Outside Africa</h2>
          <p>
            <strong>Email: </strong>
            <a href="mailto:info@sankhyana.com" className="text-blue-600 hover:underline">
              info@sankhyana.com
            </a>
          </p>
          <p>
            <strong>Whatshap: </strong>
            <a href="tel:+918951836403" className="text-blue-600 hover:underline">
              +91 89518 36403
            </a>
          </p>
          <p>
            <strong>Website: </strong>
            <a href="https://www.sankhyana.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              www.sankhyana.com
            </a>
          </p>
        </motion.div>
      </div> */}

      {/* Africa Contact Section */}
      {/* <div className="col-md-6">
        <motion.div
          variants={FadeUpText(0.3)}
          initial="initial"
          animate="animate"
          className="space-y-4 text-lg text-dark2"
        >
          <h2 className="text-xl font-semibold">Contact Us - Africa</h2>
          <p>
            <strong>Email: </strong>
            <a href="mailto:info@sankhyana.com" className="text-blue-600 hover:underline">
            info@sankhyana.com
            </a>
          </p>
          <p>
            <strong>Whatshap: </strong>
            <a href="tel:+254 794 139835" className="text-blue-600 hover:underline">
            +254 794 139835
            </a>
          </p>
          <p>
            <strong>Website: </strong>
            <a href="https://www.sankhyana.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              www.sankhyana.com
            </a>
          </p>
        </motion.div>
      </div> */}

    </div>
  </div>
</div>


     
     
     
        </motion.div>

       

        
      </div>
    </section>
  );
};

export default BannerWithContact;






